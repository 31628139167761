import React, { useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import { Box, Container, Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { MdKeyboardArrowRight, MdContentCopy } from "react-icons/md";
import { IoCodeSharp, IoDocumentTextOutline } from "react-icons/io5";
import { SiReact, SiGnubash } from "react-icons/si";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import GetApiKeyModal from "../UserProfileActions/GetApiKeyModal";
import pythonIcon from "../../assets/python.png";

const GetCode = () => {
  const location = useLocation();
  const { modelIp, modelType } = location.state || {};
  const [isGetAPIModalOpen, setIsGetAPIModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [copied, setCopied] = useState(false);

  const pythonCode = `
import requests

url = "${modelIp}"
headers = {
  "Authorization": "<PASTE_API_KEY_HERE>",
  "Content-Type": "application/json"
}
data = {
  "prompt": "Write your query here:",
  "max_new_tokens": 150,
  "temperature": 0.7,
  "top_p": 0.9,
  "repetition_penalty": 1.2
}
response = requests.post(url, json=data, headers=headers)
print(response.json())
`;

  const curlCode = `
export API_KEY=<PASTE_API_KEY_HERE>

curl -X POST "${modelIp}" \\
  -H "Authorization:$API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"prompt": "Write your query here:", "max_new_tokens": 150, "temperature": 0.7, "top_p": 0.9, "repetition_penalty": 1.2}'
`;

  const reactCode = `
import axios from 'axios';

const callApi = async () => {
  const url = "${modelIp}";
  const headers = {
    "Authorization": "<PASTE_API_KEY_HERE>",
    "Content-Type": "application/json"
  };
  const data = {
    prompt: "Write your query here:",
    max_new_tokens: 150,
    temperature: 0.7,
    top_p: 0.9,
    repetition_penalty: 1.2
  };
  
  try {
    const response = await axios.post(url, data, { headers });
    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
};

export default callApi;
`;

  const codeTabs = [
    {
      label: "Curl",
      icon: <SiGnubash size={26} color="black" />,
      code: curlCode,
      language: "bash",
    },
    {
      label: "Python",
      icon: (
        <img
          src={pythonIcon}
          alt="python icon"
          style={{ width: "24px", height: "24px" }}
        />
      ),
      code: pythonCode,
      language: "python",
    },
    {
      label: "React",
      icon: <SiReact size={26} color="#61DAFB" />,
      code: reactCode,
      language: "javascript",
    },
  ];

  const handleGetAPIToken = () => {
    setIsGetAPIModalOpen(true);
  };

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <div className="flex w-full min-h-screen">
      {/* Sidebar Section */}
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full">
        {/* Navbar Section */}
        <div className="ml-[90px]">
          <Navbar className="flex-shrink-0 fixed w-full" />
        </div>

        {/* Page content */}
        <div className="ml-[90px] px-2 pt-[84px] mb-24">
          <Container sx={{ maxWidth: { xs: "100%", sm: "1252px" } }}>
            {/* Breadcrumbs */}
            <div className="flex justify-between py-4">
              <div>
                <div className="flex items-center py-4">
                  {modelType === "huggingface model" ? (
                    <Link to="/modelStudio" state={{ openTab: 2 }}>
                      <Typography
                        variant="h6"
                        component="h2"
                        className="text-xl font-bold text-heading-black"
                      >
                        Model Studio
                      </Typography>
                    </Link>
                  ) : (
                    <Link to="/myInstance" state={{ openTab: 2 }}>
                      <Typography
                        variant="h6"
                        component="h2"
                        className="text-xl font-bold text-heading-black"
                      >
                        Dashboard
                      </Typography>
                    </Link>
                  )}
                  <MdKeyboardArrowRight className="text-2xl" />
                  <Typography
                    variant="h6"
                    component="h2"
                    className="text-xl font-bold text-heading-black"
                  >
                    Get Code
                  </Typography>
                </div>
                <p className="text-base font-normal text-gray-70 pt-1">
                  Deploy Tuned AI models
                </p>
              </div>
            </div>

            {/* Get Code Section */}
            <Paper elevation={3} sx={{ p: 2, mb: 4, bgcolor: "#fff" }}>
              <Box className="flex flex-col sm:flex-row justify-between items-center">
                <Typography>
                  You can call this prompt from the Gemini API by copying the
                  following code into your project.
                </Typography>
                <button
                  className="classic-button flex items-center justify-center mt-2 mb-4 sm:mt-4 h-[40px] min-w-[150px] text-[14px] font-semibold"
                  onClick={handleGetAPIToken}
                >
                  <IoCodeSharp className="mr-1" />
                  <span>Get API Token</span>
                </button>
              </Box>

              {/* Tab Titles */}
              <div className="min-h-[58px] pt-4 px-8 w-full bg-white shadow-md border flex gap-x-6 items-end justify-start overflow-x-auto -mb-1.5">
                {codeTabs.map((tab, index) => (
                  <span
                    key={tab.label}
                    onClick={() => handleTabChange(index)}
                    className={`min-w-fit text-[18px] font-[500] cursor-pointer pb-3 px-1 border-b-[3px] border-white flex flex-col items-center ${
                      tabValue === index
                        ? "text-gen-color font-[700] border-b-gen-color"
                        : "text-black"
                    }`}
                  >
                    {tab.icon}
                    {tab.label}
                  </span>
                ))}
              </div>

              {/* Code Block Section */}
              <Box sx={{ position: "relative" }}>
                <div className="absolute top-2 right-2 z-10 flex items-center gap-1 p-1 text-white font-medium">
                  <CopyToClipboard
                    text={codeTabs[tabValue].code}
                    onCopy={handleCopy}
                  >
                    <button className="flex items-center justify-center h-auto min-w-[100px] text-[16px]">
                      <MdContentCopy className="mr-1" size={18} />
                      <span>{copied ? "Copied!" : "Copy"}</span>
                    </button>
                  </CopyToClipboard>
                  <button className="flex items-center justify-center h-auto min-w-[100px] text-[16px]">
                    <IoDocumentTextOutline className="mr-1" size={20} />
                    <span>API Docs</span>
                  </button>
                </div>
                {/* Syntax Highlighter */}
                <SyntaxHighlighter
                  language={codeTabs[tabValue].language}
                  style={materialDark}
                >
                  {codeTabs[tabValue].code}
                </SyntaxHighlighter>
              </Box>
            </Paper>
          </Container>
        </div>
        {/* Footer Section */}
        <div className="ml-[90px]">
          <Footer />
        </div>
      </div>

      {/* GetApiKeyModal */}
      <GetApiKeyModal
        isGetAPIModalOpen={isGetAPIModalOpen}
        setIsGetAPIModalOpen={setIsGetAPIModalOpen}
      />
    </div>
  );
};

export default GetCode;

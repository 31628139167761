import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import {
  IconButton,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { MdClose, MdContentCopy } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";


const GetApiKeyModal = ({ isGetAPIModalOpen, setIsGetAPIModalOpen }) => {
  const [apiKey, setApiKey] = useState("");
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isGetAPIModalOpen) {
      const fetchApiKey = async () => {
        try {
          setLoading(true);
          const response = await axiosInstance.post("/nocode/generate_api_key");
          console.log(response.data)
          setApiKey(response.data.data.api_key);  // Assuming the API returns { apiKey: "your-key" }
          sessionStorage.setItem("apiKey", response.data.data.api_key);
          setLoading(false);
        } catch (err) {
          setError("Failed to fetch API key");
          setLoading(false);
        }
      };
      fetchApiKey();
    }
  }, [isGetAPIModalOpen]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleClose = () => {
    setIsGetAPIModalOpen(false);
  };

  return (
    <div>
      {isGetAPIModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Dark Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>

          {/* Modal Content */}
          <div className="relative z-50 w-full max-w-lg mx-auto bg-white rounded-lg shadow-lg">
            {/* Modal Header */}
            <div className="flex items-center justify-between p-4 border-b border-gray-200">
              <Typography variant="h6" className="font-semibold text-gray-800">
                Get API Key
              </Typography>
              <IconButton
                onClick={handleClose}
                className="p-1 transition-colors duration-150 hover:bg-gray-100 rounded-full"
              >
                <MdClose className="w-6 h-6 text-gray-500" />
              </IconButton>
            </div>

            {/* Modal Body */}
            <div className="p-6 space-y-4">
              {loading ? (
                <Typography>Loading...</Typography>
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : (
                <>
                  <Typography className="text-[#10001ACC] mb-8 text-xs">
                    Use this key in your model by passing it with the Key = API_KEY parameter.
                  </Typography>

                  {/* API Key Section */}
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="API Key"
                    value={apiKey || "Loading...."}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CopyToClipboard text={apiKey} onCopy={handleCopy}>
                            <Tooltip
                              title={copied ? "Copied!" : "Copy to Clipboard"}
                              arrow
                              placement="top"
                            >
                              <IconButton>
                                <MdContentCopy className="text-xl text-gray-600 hover:text-black" />
                              </IconButton>
                            </Tooltip>
                          </CopyToClipboard>
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetApiKeyModal;

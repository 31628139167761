import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import axios from "axios";
import { Container, styled, Typography, Box } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress"; // Imported CircularProgress
import axiosInstance from "../../api/axios";

const formatDataUnit = (available) => {
  if (available.endsWith("Gi")) {
    return `${available.slice(0, -2)} GB`; // Handle Gi as GB
  } else if (available.endsWith("G")) {
    return `${available.slice(0, -1)} GB`; // Handle G as GB
  } else if (available.endsWith("Mi")) {
    return `${available.slice(0, -2)} MB`; // Handle Mi as MB
  } else if (available.endsWith("M")) {
    return `${available.slice(0, -1)} MB`; // Handle M as MB
  } else {
    return "- - GB";
  }
};

// Helper function to strip ANSI codes
const stripAnsiCodes = (text) => {
  console.log(text);
  return text.replace(
    /[\u001b\u009b][[()\]#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g,
    ""
  );
};

// Function to format log lines by highlighting keywords like 'ERROR' and 'WARN'
const formatLogs = (log) => {
  if (log.includes("ERROR")) {
    return <span className="text-red-500">{log}</span>;
  }
  if (log.includes("WARN")) {
    return <span className="text-yellow-500">{log}</span>;
  }
  return <span>{log}</span>;
};

// Styled component for LinearProgress
const BorderLinearProgress = styled(LinearProgress)(({ theme, colorCode }) => ({
  height: "7px",
  width: "180px",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? colorCode : "#308fe8",
  },
}));

const ViewDetails = () => {
  const location = useLocation();
  const model = location.state?.model;
  const navigate = useNavigate();

  // Utilization States with Loading and Error Handling
  const [ramUtilData, setRamUtilData] = useState(null);
  const [ramLoading, setRamLoading] = useState(true);
  const [ramError, setRamError] = useState(false);

  const [cpuUtilData, setCpuUtilData] = useState(null);
  const [cpuLoading, setCpuLoading] = useState(true);
  const [cpuError, setCpuError] = useState(false);

  const [gpuUtilData, setGpuUtilData] = useState(null);
  const [gpuLoading, setGpuLoading] = useState(true);
  const [gpuError, setGpuError] = useState(false);

  const [logsData, setLogsData] = useState("");
  const [logsLoading, setLogsLoading] = useState(true);
  const [logsError, setLogsError] = useState(false);

  // logs
  const getLogs = async () => {
    const model_name = model?.model_name;
    const deploymentName = model?.feature?.deployment_name; // Adjust based on your model structure
    console.log(model_name);
    if (!model_name) {
      setLogsError(true);
      setLogsLoading(false);
      return;
    }
    // const url = `https://backend.dev.qubrid.com:5001/logs/${deploymentName}`;
    try {
      setLogsLoading(true);
      const response = await axiosInstance.post(`/tune/logs`, {
        model_id: model_name,
      });
      console.log("Response Logs:", response.data.logs);
      console.log("Available Logs Keys:", Object.keys(response.data.logs));

      const logs =
        response.data.logs[deploymentName] ||
        response.data.logs[Object.keys(response.data.logs)[0]];

      if (!logs) {
        // console.error(`No logs found for deployment: ${deploymentName}`);
        setLogsError(true);
        setLogsLoading(false);
        return;
      }

      setLogsData(logs);
      setLogsError(false);
    } catch (error) {
      console.error("Error fetching logs:", error);
      setLogsError(true);
    } finally {
      setLogsLoading(false);
    }
  };

  // Fetch RAM Utilization
  const getRamUtilization = async () => {
    const model_name = model?.model_name;
    // const url = `http://${model.feature.service_ip}/ram-used`;
    try {
      setRamLoading(true);
      const response = await axiosInstance.post(`/tune/ram_used`, {
        model_id: model_name,
      });
      setRamUtilData(response.data?.ram_info);
      setRamError(false);
    } catch (error) {
      console.error("Error fetching RAM Utilization:", error);
      setRamError(true);
    } finally {
      setRamLoading(false);
    }
  };

  // Fetch CPU Utilization
  const getCpuUtilization = async () => {
    const model_name = model?.model_name;
    // const url = `http://${model.feature.service_ip}/cpu-utilization`;
    try {
      setCpuLoading(true);
      const response = await axiosInstance.post(`/tune/cpu_used`, {
        model_id: model_name,
      });
      setCpuUtilData(response.data?.cpu_utilization);
      setCpuError(false);
    } catch (error) {
      console.error("Error fetching CPU Utilization:", error);
      setCpuError(true);
    } finally {
      setCpuLoading(false);
    }
  };

  // Fetch GPU Utilization
  const getGpuUtilization = async () => {
    const model_name = model?.model_name;
    // const url = `http://${model.feature.service_ip}/gpu-used`; // Fixed typo here
    try {
      setGpuLoading(true);
      const response = await axiosInstance.post(`/tune/gpu_used`, {
        model_id: model_name,
      });
      // const response = await axiosInstance.get(`tune/gpu_used`);
      // Assuming gpu_info is an array
      const gpuInfo = response.data?.gpu_info?.[0]?.utilization_gpu;
      setGpuUtilData(gpuInfo);
      setGpuError(false);
    } catch (error) {
      console.error("Error fetching GPU Utilization:", error);
      setGpuError(true);
    } finally {
      setGpuLoading(false);
    }
  };

  useEffect(() => {
    if (model && model.feature?.service_ip) {
      // Initial calls to get utilization data
      getRamUtilization();
      getCpuUtilization();
      getGpuUtilization();
      getLogs(); // Fetch logs initially

      // Set intervals for periodic calls
      const intervalId1 = setInterval(() => {
        getRamUtilization();
      }, 1000 * 60); // 1 minute interval

      const intervalId2 = setInterval(() => {
        getCpuUtilization();
      }, 1000 * 5 * 60); // 5 minutes interval

      const intervalId3 = setInterval(() => {
        getGpuUtilization();
      }, 1000 * 10); // 10 seconds interval

      const intervalId4 = setInterval(() => {
        getLogs();
      }, 1000 * 30); // 30 seconds interval for logs

      // Cleanup function to clear intervals on component unmount
      return () => {
        clearInterval(intervalId1);
        clearInterval(intervalId2);
        clearInterval(intervalId3);
        clearInterval(intervalId4);
      };
    }
  }, [model]);

  // Helper function to parse percentage strings to numbers
  const parsePercentage = (percentage) => {
    if (percentage === undefined || percentage === null) return 0;
    let value = percentage;
    if (typeof value === "string") {
      value = value.replace("%", "").trim();
    }
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  };

  // Clean the logs data
  const cleanedLogs = stripAnsiCodes(logsData);

  return (
    <div className="flex w-full min-h-screen">
      {/* Sidebar Section */}
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full">
        <div className="ml-[90px]">
          {/* Navbar Section */}
          <Navbar className="flex-shrink-0 fixed w-full" />
        </div>
        {/* Page Content */}
        <div className="ml-[90px] px-2 pt-[84px] mb-24">
          <Container sx={{ maxWidth: { xs: "100%", sm: "1252px" } }}>
            {/* Breadcrumbs */}
            <div className="flex justify-between py-4">
              <div className="flex items-center">
                <Link to="/myInstance" state={{ openTab: 2 }}>
                  <Typography
                    variant="h6"
                    component="h2"
                    className="text-xl font-bold text-heading-black hover:text-blue-500"
                  >
                    Dashboard
                  </Typography>
                </Link>

                <MdKeyboardArrowRight className="text-2xl" />
                <Link
                  to={`/view-detail`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    className="text-xl font-bold text-heading-black hover:text-blue-500"
                  >
                    View Detail
                  </Typography>
                </Link>
              </div>
            </div>
            {/* Model Information Section */}
            <div className="bg-white shadow-md rounded-lg p-4 lg:p-6 mb-6 min-h-[132px] w-full">
              <Typography variant="h6" className="text-lg font-bold">
                Model Information
              </Typography>
              {/* Using grid for responsive layout */}
              <Box className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-4 break-words">
                <Box className="flex flex-col text-sm break-words">
                  <Typography variant="body2" className="mb-1">
                    <strong>Model Name:</strong>
                  </Typography>
                  <Typography variant="body2">{model?.model_name}</Typography>
                </Box>
                <Box className="flex flex-col text-sm">
                  <Typography variant="body2" className="mb-1">
                    <strong>Base Model Name:</strong>
                  </Typography>
                  <Typography variant="body2">{model?.model_id}</Typography>
                </Box>
                <Box className="flex flex-col text-sm">
                  <Typography variant="body2" className="mb-1">
                    <strong>Epochs:</strong>
                  </Typography>
                  <Typography variant="body2">
                    {model?.feature?.epochs}
                  </Typography>
                </Box>
                <Box className="flex flex-col text-sm">
                  <Typography variant="body2" className="mb-1">
                    <strong>Max Steps:</strong>
                  </Typography>
                  <Typography variant="body2">
                    {model?.feature?.max_steps}
                  </Typography>
                </Box>
              </Box>
            </div>

            {/* Utilization Sections */}
            <div className="h-auto pb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 overflow-auto">
              {/* GPU Utilization */}
              <div className="bg-white flex flex-col justify-between w-full">
                <div className="h-[250px] p-4 border shadow-md rounded-[8px] flex flex-col justify-start">
                  <Typography
                    variant="subtitle1"
                    className="text-[16px] font-[600]"
                  >
                    GPU Utilization
                  </Typography>
                  {gpuLoading ? (
                    <Box className="mt-4 flex justify-center">
                      <CircularProgress />
                    </Box>
                  ) : gpuError || !gpuUtilData ? (
                    <Typography variant="body2" className="mt-4 text-red-500">
                      Still creating, please wait...
                    </Typography>
                  ) : (
                    <>
                      <div className="flex flex-col items-center justify-center h-[250px] w-full">
                        <Gauge
                          className="-mt-8"
                          cornerRadius="0%"
                          startAngle={-90}
                          endAngle={90}
                          value={parsePercentage(gpuUtilData)}
                          maxValue={100}
                          width={window.innerWidth < 640 ? 200 : 340} // Set width based on screen size
                          height={window.innerWidth < 640 ? 100 : 134} // Set height based on screen size
                          sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                              fontSize: window.innerWidth < 640 ? 12 : 15, // Adjust font size for responsiveness
                              transform: "translate(0px, -20px)",
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                              fill: "#0a77ff",
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                              fill: "#f2f3f9",
                            },
                          })}
                        />
                        <div className="flex justify-between w-full mt-2">
                          <Typography variant="body2" className="text-gray-400">
                            Total
                          </Typography>
                          <Typography variant="body2" className="text-gray-400">
                            Remaining
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* CPU Utilization */}
              <div className="bg-white flex flex-col justify-between w-full">
                <div className="h-[250px] p-4 border shadow-md rounded-[8px] flex flex-col justify-start">
                  <Typography
                    variant="subtitle1"
                    className="text-[16px] font-[600]"
                  >
                    CPU Utilization
                  </Typography>
                  {cpuLoading ? (
                    <Box className="mt-4 flex justify-center">
                      <CircularProgress />
                    </Box>
                  ) : cpuError || !cpuUtilData ? (
                    <Typography variant="body2" className="mt-4 text-red-500">
                      Still creating, please wait...
                    </Typography>
                  ) : (
                    <>
                      <div className="flex flex-col items-center justify-center h-[250px] w-full">
                        <Gauge
                          className="-mt-8"
                          cornerRadius="0%"
                          startAngle={-90}
                          endAngle={90}
                          value={parsePercentage(cpuUtilData)}
                          maxValue={100}
                          width={window.innerWidth < 640 ? 200 : 340} 
                          height={window.innerWidth < 640 ? 100 : 134} 
                          sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                              fontSize: window.innerWidth < 640 ? 12 : 15,
                              transform: "translate(0px, -25px)",
                            },
                            [`& .${gaugeClasses.valueLabel}`]: {
                              transform: "translate(0px, 25px)",
                            },
                            [`& .${gaugeClasses.root}`]: {
                              [`& .${gaugeClasses.progress}`]: {
                                stroke: "#d32f2f",
                              },
                            },
                          })}
                          label="Utilization"
                        />
                        <div className="flex justify-between w-full mt-2">
                          <Typography variant="body2" className="text-gray-400">
                            Total
                          </Typography>
                          <Typography variant="body2" className="text-gray-400">
                            Remaining
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* RAM Utilization */}
              <div className="bg-white flex flex-col justify-between w-full sm:ml-[60%] lg:ml-0">
                <div className="h-[250px] p-4 border shadow-md rounded-[8px] flex flex-col justify-start">
                  <Typography
                    variant="subtitle1"
                    className="text-[16px] font-[600]"
                  >
                    RAM Utilization
                  </Typography>
                  {ramLoading ? (
                    <Box className="mt-4 flex justify-center">
                      <CircularProgress />
                    </Box>
                  ) : ramError || !ramUtilData ? (
                    <Typography variant="body2" className="mt-4 text-red-500">
                      Still creating, please wait...
                    </Typography>
                  ) : (
                    <>
                      <div className="flex flex-col items-center justify-center h-[250px] w-full">
                        <Gauge
                          className="-mt-6"
                          cornerRadius="0%"
                          startAngle={-90}
                          endAngle={90}
                          value={parsePercentage(ramUtilData.used_percentage)}
                          maxValue={100}
                          width={window.innerWidth < 640 ? 200 : 340} // Adjust width for responsiveness
                          height={window.innerWidth < 640 ? 100 : 134} // Adjust height for responsiveness
                          text={({ value, valueMax }) =>
                            ramUtilData.used
                              ? `${ramUtilData.used?.slice(0, -2)} GB \n Used`
                              : "-"
                          }
                          sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                              fontSize: window.innerWidth < 640 ? 12 : 15,
                              transform: "translate(0px, -25px)",
                            },
                            [`& .${gaugeClasses.valueLabel}`]: {
                              transform: "translate(0px, 25px)",
                            },
                            [`& .${gaugeClasses.root}`]: {
                              [`& .${gaugeClasses.progress}`]: {
                                stroke: "#2e7d32",
                              },
                            },
                          })}
                          label="Utilization"
                        />
                        <div className="flex justify-between w-full mt-2">
                          <Typography variant="body2" className="text-gray-400">
                            <p>Total</p>
                            <p>{ramUtilData.total?.slice(0, -1) || "- - G"}B</p>
                          </Typography>
                          <Typography variant="body2" className="text-gray-400">
                            <p>Remaining</p>
                            <p>{formatDataUnit(ramUtilData.free || "")}</p>
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Logs Section */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <Typography variant="h6" className="text-lg font-bold mb-4">
                Logs
              </Typography>
              {logsLoading ? (
                <Box className="flex justify-center">
                  <CircularProgress /> {/* Loading indicator for logs */}
                </Box>
              ) : logsError ? (
                <Typography variant="body2" className="text-red-500">
                  Error fetching logs. Please try again later.
                </Typography>
              ) : (
                <Box className="max-h-60 overflow-y-auto text-sm bg-gray-900 text-green-400 p-4 rounded">
                  <pre>
                    {cleanedLogs.split("\n").map((log, idx) => (
                      <div key={idx}>{formatLogs(log)}</div>
                    ))}
                  </pre>
                </Box>
              )}
            </div>
          </Container>
        </div>
        {/* Footer Section */}
        <div className={`ml-[90px]`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;

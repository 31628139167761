import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { Context } from "../../Context";
import { toast } from "react-toastify";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoCodeSharp } from "react-icons/io5";

import { TbTrashOff, TbExternalLinkOff } from "react-icons/tb";
import { Container, Tooltip } from "@mui/material";
import { GoLinkExternal } from "react-icons/go";
import loader from "../../assets/ui_img/loader.svg";
import {
  InfoOutlined,
  PlayCircleOutlineOutlined,
  StopCircleOutlined,
} from "@mui/icons-material";
import moment from "moment-timezone";
import { GrDocumentCsv, GrDocumentPdf } from "react-icons/gr";
import axiosInstance from "../../api/axios";
import { HiDotsVertical } from "react-icons/hi";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";

import { FiEye } from "react-icons/fi";
// import { IoLogOutOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import DeployModal from "../DashboardActions/DeployModal";
import { ClipLoader } from "react-spinners";

function MyInstance() {
  const location = useLocation();
  const [openTab, setOpenTab] = useState(location.state || 1);
  const { open } = useContext(Context);
  // const [selectedTab, setSelectedTab] = useState(0);
  const [myinstance, setMyinstance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [synthHisRes, setSynthHisRes] = useState([]);
  const [models, setModels] = useState([]);
  const [modelsLoading, setModelsLoading] = useState(false);
  const [modelsError, setModelsError] = useState(null);
  const [deleteIns, setDeleteIns] = useState(null);
  const [deleteTunedModel, setDeleteTunedModel] = useState(null);
  const [launchingInstance, setLaunchingInstance] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const navigate = useNavigate();
  const [showActionsForModel, setShowActionsForModel] = useState(null);
  const actionRef = useRef(null);
  const [isBottomOverflowing, setIsBottomOverflowing] = useState(false);
  const [selectedModel, setSelectedModelName] = useState(null);
  const [selectedmodelId, setSelectedModelId] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [isDeployModalOpen, setIsDeployModalOpen] = useState(false);
  const LoaderComponent = () => (
    <ClipLoader color={"#000"} loading={true} size={24} />
  );
  // const handleAction = (modelId) => {
  //   setShowActionsForModel((prevModelId) =>
  //     prevModelId === modelId ? null : modelId
  //   );
  // };
  // UseEffect to detect the tab from the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    switch (tab) {
      case "instance":
        setOpenTab(1);
        break;
      case "tuned":
        setOpenTab(2);
        break;
      case "synthetic":
        setOpenTab(3);
        break;
      default:
        setOpenTab(1);
        break;
    }
  }, [location.search]);
  // Handle tab change and update URL
  const handleTabChange = (tabIndex) => {
    setOpenTab(tabIndex);
    switch (tabIndex) {
      case 1:
        navigate(`?tab=instance`);
        break;
      case 2:
        navigate(`?tab=tuned`);
        break;
      case 3:
        navigate(`?tab=synthetic`);
        break;
      default:
        navigate(`?tab=instance`);
        break;
    }
  };

  const viewDetails = (model) => {
    // Logic to view model details
    navigate("/view-detail", { state: { model } });
  };

  const deployModel = (modelId, modelName) => {
    // Logic to deploy the model
    // navigate("/deploy", { state: { modelId } });
    setSelectedModelName(modelName);
    // setSelectedPrice(price);
    setSelectedModelId(modelId);
    setIsDeployModalOpen(true);
  };

  const getCodeModel = (modelId, modelIp,modelType) => {
    navigate("/getCode", { state: { modelIp, modelId,modelType } });
  };
  const getUiModel = (model, modelIp) => {
    navigate("/getUiT", { state: { model, modelIp } });
  };
  // Function to open the confirmation modal
  const handleDeleteModel = (modelId, model) => {
    console.log("Model ID to delete:", modelId); // Debugging
    setDeleteTunedModel(model); // Store the ID of the model to delete
    setShowDeleteConfirmationModal(true); // Show the modal
  };

  // Function to delete the model and close the modal
  const confirmDeleteModel = () => {
    console.log("Confirm delete for model ID:", deleteTunedModel); // Debugging
    if (deleteTunedModel) {
      setShowDeleteConfirmationModal(false); // Close the modal
      deleteModel(deleteTunedModel); // Call delete function with the stored model ID
    }
  };

  // Function to delete the model using the API
  const deleteModel = async (model) => {
    console.log("Deleting model ID:", model); // Debugging

    setLoading(true);

    // Prepare request payload
    const requestData = {
      model_name: model?.model_name,
      type: model?.type,
    };

    // Determine API endpoint based on model type
    let apiEndpoint = "";
    if (model?.type === "tuned model") {
      apiEndpoint = "/nocode/delete-tuned-model";
    } else if (model?.type === "Deployed model") {
      apiEndpoint = "/tune/delete_deploy";
    } else {
      console.error("Invalid model type:", model?.type);
      toast("Invalid model type");
      setLoading(false);
      return; // Exit if model type is not valid
    }

    try {
      const response = await axiosInstance.delete(apiEndpoint, {
        data: requestData,
      });
      console.log("Delete response:", response); // Debugging
      toast(response?.data?.message);
      loadModels(); // Reload models after deletion
    } catch (error) {
      console.error("Error deleting model:", error); // Debugging
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          deleteModel(model); // Retry after refreshing token
        }
      } else {
        console.error("Error deleting model:", error);
        toast(error.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the Start Deployment API
  const handleStartDeploy = async (model) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/tune/start_deploy", {
        model_name: model?.model_name,
      });
      window.location.reload();
      console.log("Start deployment success:", response.data);
      // Handle success (e.g., update UI or model status)
    } catch (error) {
      console.error("Error starting deployment:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the Stop Deployment API
  const handleStopDeploy = async (model) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/tune/stop_deploy", {
        model_name: model?.model_name,
      });
      window.location.reload();
      console.log("Stop deployment success:", response.data);
      // Handle success (e.g., update UI or model status)
    } catch (error) {
      console.error("Error stopping deployment:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshToken = async () => {
    try {
      await axiosInstance.post("/user/refresh_token");
      return true;
    } catch (error) {
      console.log("Refresh token error:", error);
      return false;
    }
  };

  // Function to load instances
  const loadInstance = async () => {
    const url = `/manage/user_instances?page=1&page_size=10`;
    try {
      const response = await axiosInstance.get(url);
      const data = response.data;
      setMyinstance(data.instances);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          loadInstance();
        }
      } else {
        console.error("Error loading instances:", error);
      }
    }
  };

  // Function to load synth history
  const loadSynthHistory = async () => {
    const url = `/user/synth/synth-history`;
    try {
      const response = await axiosInstance.get(url);
      const data = response?.data?.response;
      setSynthHisRes(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          loadSynthHistory();
        }
      } else {
        console.error("Error loading synth history:", error);
      }
    }
  };

  // Function to load models
  const loadModels = async () => {
    // setModelsLoading(true);
    try {
      const response = await axiosInstance.get("/nocode/get-models");
      const modelsData = response?.data?.data;
      if (response.data.success && modelsData && modelsData.length > 0) {
        setModels(modelsData);
        console.log("Model details: " + models);
      } else {
        // Show nothing when array is empty
        setModels([]); // Clear models when there's no data
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          loadModels();
        }
      } else {
        setModelsError(error.message || "Failed to fetch models");
      }
    } finally {
      setModelsLoading(false);
    }
  };

  // Load data on component mount
  // useEffect(() => {
  //   loadInstance();
  //   loadSynthHistory();
  // }, []);
  useEffect(() => {
    // Call all functions once when the component mounts
    loadModels();
    loadInstance();
    loadSynthHistory();

    // Set interval to call them every 30 seconds
    const interval = setInterval(() => {
      loadModels();
      loadInstance();
      loadSynthHistory();
    }, 30000);

    // Cleanup interval when component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs once when mounted

  // Load models when Tuned Model tab is active
  useEffect(() => {
    if (openTab === 2 && models.length === 0) {
      loadModels();
    }
  }, [openTab]);

  useEffect(() => {
    if (location.state && location.state.openTab) {
      setOpenTab(location.state.openTab);
    }
  }, [location.state]);

  // Function to convert GMT time to local time
  const convertToLocalTime = (gmtTimeStr) => {
    return moment
      .tz(gmtTimeStr, "ddd, DD MMM YYYY HH:mm:ss [GMT]", "GMT")
      .tz(moment.tz.guess())
      .format("YYYY-MM-DD HH:mm:ss z");
  };

  // Function to handle opening Jupyter Notebook
  const handleOpen = async (ele) => {
    const notebookUrl = ele.jupyter_token; // Assuming jupyter_token contains the full URL
    window.open(notebookUrl, "_blank");
  };

  // Function to delete an instance
  const deleteInstance = async (ele) => {
    setLoading(true);
    sessionStorage.clear();
    const requestData = {
      plan_id: ele.plan_id,
      instance_id: ele.instance_id,
    };

    try {
      const response = await axiosInstance.post(
        `/manage/delete-instance`,
        requestData
      );
      toast(response?.data?.message);
      const cpuid = response.data.instance;
      try {
        await axiosInstance.post(`/manage/InActivatePlan`, { plan: cpuid });
        loadInstance();
      } catch (secondError) {
        console.error("Second API call error:", secondError);
      }
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          deleteInstance(ele);
        }
      } else {
        console.error("Error deleting instance:", error);
        toast(error.response?.data?.message || "Something went wrong");
        setLoading(false);
      }
    }
    setTimeout(() => {
      loadInstance();
    }, 1500);
  };

  // Function to start an instance
  const handleStart = async (ele) => {
    setLaunchingInstance(true);
    try {
      const response = await axiosInstance.post(`/manage/relaunch`, {
        instance_id: ele.instance_id,
      });
      loadInstance();
      toast(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          handleStart(ele);
        }
      } else {
        console.error("Error starting instance:", error);
        toast(error.response?.data?.message || "Failed to start instance");
      }
    } finally {
      setLaunchingInstance(false);
    }
  };

  // Function to stop an instance
  const handleStop = async (ele) => {
    setLaunchingInstance(true);
    try {
      const response = await axiosInstance.post(`/manage/stop-instance`, {
        instance_id: ele.instance_id,
      });
      loadInstance();
      toast(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          handleStop(ele);
        }
      } else {
        console.error("Error stopping instance:", error);
        toast(error.response?.data?.message || "Failed to stop instance");
      }
    } finally {
      setLaunchingInstance(false);
    }
  };

  // Function to handle file downloads
  const handleDownload = (url) => {
    window.open(url, "_blank");
  };

  // const handleAction = () => {
  //   setOpenAction(!openAction);
  // };

  const handleAction = (modelId) => {
    setShowActionsForModel((prevModelId) =>
      prevModelId === modelId ? null : modelId
    );
  };

  // Close the action if clicked outside the div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionRef.current && !actionRef.current.contains(event.target)) {
        setShowActionsForModel(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (openAction) {
      const actionElement = actionRef.current;
      if (actionElement) {
        const { bottom } = actionElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if the dropdown's bottom would overflow the viewport
        if (bottom > windowHeight) {
          setIsBottomOverflowing(true); // Overflow detected
        } else {
          setIsBottomOverflowing(false); // No overflow
        }
      }
    }
  }, [openAction]);

  const handleOptionClick = (option) => {
    if (option === "viewDetail") {
      navigate("/view-detail");
    } else if (option === "deploy") {
      navigate("/deploy");
    } else if (option === "delete") {
      navigate("/delete");
    }
  };
  function capitalizeWords(str) {
    if (!str) return ""; // Check if the string is undefined, null, or empty
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <div className="flex w-full min-h-screen">
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full">
        <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
          <Navbar />
        </div>
        {/* Page Content */}
        <div
          className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-24 mb-24`}
        >
          <Container>
            <div className="pb-6 text-secondry-clr text-base">
              <span className="font-bold">Dashboard</span>
            </div>
            <div className="flex flex-auto">
              <div className="w-screen">
                <ul
                  className="flex list-none bg-white pt-4 border-b space-x-8 pl-2"
                  role="tablist"
                >
                  <li
                    className={`text-base font-bold px-1.5 pb-3 ${
                      openTab === 1
                        ? "text-tab-active-color border-b-4 border-tab-active-color"
                        : "text-gray-60"
                    }`}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleTabChange(1);
                      }}
                      data-toggle="tab"
                      href="#link1"
                      role="tablist"
                    >
                      My Instances
                    </a>
                  </li>

                  <li
                    className={`text-base font-bold px-1.5 pb-3 ${
                      openTab === 2
                        ? "text-tab-active-color border-b-4 border-tab-active-color"
                        : "text-gray-60"
                    }`}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleTabChange(2);
                      }}
                      data-toggle="tab"
                      href="#link2"
                      role="tablist"
                    >
                      My Models
                    </a>
                  </li>

                  <li
                    className={`text-base font-bold px-1.5 pb-3 ${
                      openTab === 3
                        ? "text-tab-active-color border-b-4 border-tab-active-color"
                        : "text-gray-60"
                    }`}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleTabChange(3);
                      }}
                      data-toggle="tab"
                      href="#link3"
                      role="tablist"
                    >
                      Synthetic Data
                    </a>
                  </li>
                </ul>

                <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                  <div className="flex-auto">
                    <div className="tab-content tab-space">
                      {/* Instance Tab */}
                      <div
                        className={openTab === 1 ? "block" : "hidden"}
                        id="link1"
                      >
                        <table className="w-full">
                          <thead className="bg-table-header-bg-color">
                            <tr>
                              <th className="py-4 px-10 text-sm font-semibold text-left">
                                Instance Type
                              </th>
                              <th className="py-4 px-10 text-sm font-semibold text-left">
                                Type
                              </th>
                              <th className="py-4 px-10 text-sm font-semibold text-left">
                                Date & Time
                              </th>
                              <th className="py-4 px-10 text-sm font-semibold text-left">
                                Status
                              </th>
                              <th className="py-4 px-10 text-sm font-semibold text-left">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {myinstance?.map((ele, i) => (
                              <tr
                                key={ele.instance_id}
                                className={`${
                                  i % 2 === 0 ? "bg-white" : "bg-light-gray-1"
                                } w-full`}
                              >
                                <td className="py-4 px-10 text-sm font-normal">
                                  {ele.plan_name}
                                </td>
                                <td className="py-4 px-10 text-sm font-normal">
                                  {ele.instance_type.toUpperCase()}
                                </td>
                                <td className="py-4 px-2 text-sm font-normal">
                                  {convertToLocalTime(ele.instance_launch_time)}
                                </td>
                                <td className="py-4 text-sm font-normal">
                                  <span
                                    className={`ml-4 rounded-lg inline-flex items-center py-1 px-2 whitespace-nowrap font-semibold ${
                                      ele.status === "intializing"
                                        ? "bg-green-100 text-green-500"
                                        : ele.status === "terminated"
                                        ? "bg-red-100 text-red-500"
                                        : "text-black" // default color if none match
                                    }`}
                                  >
                                    {ele.status}
                                  </span>
                                </td>
                                {ele.status === "running" ? (
                                  <td className="py-2 px-10 flex">
                                    <div className="mr-4">
                                      <Tooltip
                                        title="Stop"
                                        placement="left"
                                        arrow
                                      >
                                        <button
                                          className="mt-1"
                                          disabled={launchingInstance}
                                          onClick={() => handleStop(ele)}
                                        >
                                          <StopCircleOutlined className="h-5 w-5 stroke-[0.5]" />
                                        </button>
                                      </Tooltip>
                                    </div>

                                    <div className="mr-4">
                                      <Tooltip
                                        title="View"
                                        placement="left"
                                        arrow
                                      >
                                        <button
                                          className="mt-2"
                                          onClick={() => handleOpen(ele)}
                                        >
                                          <GoLinkExternal className="h-5 w-5 stroke-[0.5]" />
                                        </button>
                                      </Tooltip>
                                    </div>
                                    <Tooltip
                                      title="Delete"
                                      placement="right"
                                      arrow
                                    >
                                      <button
                                        onClick={() => {
                                          setShowConfirmationModal(true);
                                          setDeleteIns(ele);
                                        }}
                                      >
                                        <FaRegTrashAlt className="h-5 w-5" />
                                      </button>
                                    </Tooltip>
                                    {/* Confirmation Modal */}
                                    {showConfirmationModal &&
                                      deleteIns === ele && (
                                        <div className="fixed z-10 inset-0 overflow-y-auto">
                                          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                            <div
                                              className="fixed inset-0 transition-opacity"
                                              aria-hidden="true"
                                            >
                                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                            </div>
                                            <span
                                              className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                              aria-hidden="true"
                                            >
                                              &#8203;
                                            </span>
                                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                              <div>
                                                <div className="mt-3 text-center sm:mt-5">
                                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Are you sure you want to
                                                    delete?
                                                  </h3>
                                                </div>
                                                <div className="mt-5 sm:mt-6 flex justify-center">
                                                  <button
                                                    type="button"
                                                    className="cancel-button"
                                                    onClick={() => {
                                                      setShowConfirmationModal(
                                                        false
                                                      );
                                                      deleteInstance(deleteIns);
                                                    }}
                                                  >
                                                    Delete
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="general-button ml-5"
                                                    onClick={() =>
                                                      setShowConfirmationModal(
                                                        false
                                                      )
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </td>
                                ) : ele.status === "stopped" ? (
                                  <td className="py-2 px-10 flex items-center">
                                    <div className="mr-4">
                                      <Tooltip
                                        title="Start"
                                        placement="left"
                                        arrow
                                      >
                                        <button
                                          disabled={launchingInstance}
                                          onClick={() => handleStart(ele)}
                                        >
                                          <PlayCircleOutlineOutlined className="h-5 w-5 stroke-[0.5]" />
                                        </button>
                                      </Tooltip>
                                    </div>
                                    <div className="mr-4">
                                      <button className="mt-2">
                                        <TbExternalLinkOff className="h-5 w-5 text-gray-400" />
                                      </button>
                                    </div>
                                    <Tooltip
                                      title="Delete"
                                      placement="right"
                                      arrow
                                    >
                                      <button
                                        onClick={() => {
                                          setShowConfirmationModal(true);
                                          setDeleteIns(ele);
                                        }}
                                      >
                                        <FaRegTrashAlt className="h-5 w-5" />
                                      </button>
                                    </Tooltip>
                                    {/* Confirmation Modal */}
                                    {showConfirmationModal &&
                                      deleteIns === ele && (
                                        <div className="fixed z-10 inset-0 overflow-y-auto">
                                          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                            <div
                                              className="fixed inset-0 transition-opacity"
                                              aria-hidden="true"
                                            >
                                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                            </div>
                                            <span
                                              className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                              aria-hidden="true"
                                            >
                                              &#8203;
                                            </span>
                                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                              <div>
                                                <div className="mt-3 text-center sm:mt-5">
                                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Are you sure you want to
                                                    delete?
                                                  </h3>
                                                </div>
                                                <div className="mt-5 sm:mt-6 flex justify-center">
                                                  <button
                                                    type="button"
                                                    className="cancel-button"
                                                    onClick={() => {
                                                      setShowConfirmationModal(
                                                        false
                                                      );
                                                      deleteInstance(deleteIns);
                                                    }}
                                                  >
                                                    Delete
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="general-button ml-5"
                                                    onClick={() =>
                                                      setShowConfirmationModal(
                                                        false
                                                      )
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </td>
                                ) : (
                                  <td className="py-4 px-4 flex items-center">
                                    <div className="mr-4">
                                      <Tooltip
                                        title="Unknown"
                                        placement="left"
                                        arrow
                                      >
                                        <button>
                                          <InfoOutlined className="h-5 w-5 text-gray-400" />
                                        </button>
                                      </Tooltip>
                                    </div>
                                    <div className="mr-4">
                                      <button className="mt-2">
                                        <TbExternalLinkOff className="h-5 w-5 text-gray-400" />
                                      </button>
                                    </div>
                                    <Tooltip
                                      title="Deleted"
                                      placement="right"
                                      arrow
                                    >
                                      <button>
                                        <TbTrashOff className="h-5 w-5 text-gray-400" />
                                      </button>
                                    </Tooltip>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        {loading && (
                          <div>
                            <div className="absolute inset-0 flex flex-col items-center justify-center z-[200]">
                              <img
                                src={loader}
                                alt="Loading..."
                                className="spinner"
                              />
                              <p className="font-medium text-lg text-white">
                                Please wait...
                              </p>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                          </div>
                        )}
                      </div>

                      {/* Tuned Model Tab */}
                      <div
                        className={`${
                          openTab === 2 ? "block" : "hidden"
                        } overflow-x-auto`}
                        id="link2"
                      >
                        {modelsLoading ? (
                          <div className="flex justify-center items-center">
                            <img
                              src={loader}
                              alt="Loading..."
                              className="spinner"
                            />
                            <p className="ml-2">Loading models...</p>
                          </div>
                        ) : modelsError ? (
                          <div className="text-red-500">
                            Error: {modelsError}
                          </div>
                        ) : (
                          <table className="w-full min-w-fit">
                            <thead className="bg-table-header-bg-color">
                              <tr>
                                <th className="py-4 px-10 text-sm font-semibold text-left">
                                  Model Name
                                </th>
                                <th className="py-4 px-10 text-sm font-semibold text-left">
                                  GPU
                                </th>
                                <th className="py-4 px-10 text-sm font-semibold text-left">
                                  Created On
                                </th>
                                <th className="py-4 px-10 text-sm font-semibold text-left">
                                  Base Model
                                </th>
                                <th className="py-4 px-10 text-sm font-semibold text-left">
                                  Type
                                </th>
                                <th className=" py-4 px-10 text-sm font-semibold text-center">
                                  Status
                                </th>
                                <th className="py-4 px-10 text-sm font-semibold text-left">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {models.map((model, i) => (
                                <tr
                                  key={model._id}
                                  className={`${
                                    i % 2 === 0 ? "bg-white" : "bg-light-gray-1"
                                  } w-full`}
                                >
                                  <td className="py-4 px-10 text-sm font-normal">
                                    {model.model_name}
                                  </td>
                                  <td className="py-4 px-10 text-sm font-normal">
                                    {model.feature.gpu_name}
                                  </td>
                                  <td className="py-4 px-10 text-sm font-normal">
                                    {convertToLocalTime(model.create_date_time)}
                                  </td>
                                  <td className="py-4 px-10 text-sm font-normal">
                                    {model.model_id}
                                  </td>
                                  <td className="text-sm font-medium text-center">
                                    <span
                                      className={`rounded-lg py-1 px-2 inline-flex items-center whitespace-nowrap ${model.type}`}
                                    >
                                      {capitalizeWords(model.type)}
                                    </span>
                                  </td>

                                  <td className="text-sm font-medium text-center">
                                    <span
                                      className={`ml-4 rounded-lg py-1 px-2 inline-flex items-center whitespace-nowrap font-semibold ${
                                        model.status === "Tuning In-progress"
                                          ? "bg-yellow-100 text-yellow-500"
                                          : model.status === "Container Created"
                                          ? "bg-sky-100 text-sky-500"
                                          : model.status === "Model Loading"
                                          ? "bg-purple-100 text-purple-500"
                                          : model.status === "Tuning Failed"
                                          ? "bg-red-100 text-red-500"
                                          : model.status === "Tuning Completed"
                                          ? "bg-green-100 text-green-500"
                                          : model.status ===
                                            "Tuning Initialized"
                                          ? "bg-pink-100 text-pink-500"
                                          : model.status === "Deployment Ready"
                                          ? "bg-blue-100 text-blue-500"
                                          : model.status ===
                                            "Deployment Initialized"
                                          ? "bg-indigo-100 text-indigo-600"
                                          : model.status ===
                                            "Deployment Stopped"
                                          ? "bg-red-100 text-red-500"
                                          : model.status ===
                                            "Deployment In-Progress"
                                          ? "bg-orange-100 text-orange-500"
                                          : "text-black py-4 px-10 text-sm font-normal"
                                      }`}
                                    >
                                      {model.status}
                                    </span>
                                  </td>
                                  <td>
                                    <HiDotsVertical
                                      className="mt-0.5 ml-12 cursor-pointer"
                                      onClick={() => handleAction(model._id)}
                                    />
                                    {/* Dropdown buttons based on the model status */}
                                    {showActionsForModel === model._id && (
                                      <div
                                        ref={actionRef}
                                        className="absolute right-0 lg:right-auto z-10 bg-white shadow-lg rounded-md p-2 mt-2 w-48  ring-1 ring-black ring-opacity-5"
                                      >
                                        {/* View Details Button */}
                                        <button
                                          className={`flex items-center w-full text-base px-4 py-2 ${
                                            [
                                              "Tuning Initialized",
                                              "Model Loading",
                                              "Tuning In-progress",
                                            ].includes(model.status)
                                              ? "text-black" // Active state
                                              : "text-gray-400 cursor-not-allowed pointer-events-none" // Inactive state
                                          }`}
                                          disabled={
                                            ![
                                              "Tuning Initialized",
                                              "Model Loading",
                                              "Tuning In-progress",
                                            ].includes(model.status)
                                          }
                                          onClick={() => viewDetails(model)}
                                        >
                                          <FiEye className="mr-2 h-5 w-5" />{" "}
                                          View Details
                                        </button>
                                        {/* Deploy Button */}
                                        <button
                                          className={`flex items-center w-full text-base px-4 py-2 ${
                                            model.status === "Tuning Completed"
                                              ? "text-black"
                                              : "text-gray-400 cursor-not-allowed"
                                          }`}
                                          disabled={
                                            model.status !== "Tuning Completed"
                                          }
                                          onClick={() =>
                                            deployModel(
                                              model._id,
                                              model.model_name
                                            )
                                          }
                                        >
                                          <TbExternalLinkOff className="mr-2 h-5 w-5" />{" "}
                                          Deploy
                                        </button>
                                        {/* Get Code Button */}
                                        <button
                                          className={`flex items-center w-full text-base px-4 py-2 ${
                                            model.status === "Deployment Ready"
                                              ? "text-black"
                                              : "text-gray-400 cursor-not-allowed"
                                          }`}
                                          disabled={
                                            model.status !== "Deployment Ready"
                                          }
                                          onClick={() =>
                                            getCodeModel(
                                              model._id,
                                              model.feature.service_ip,model.type
                                            )
                                          }
                                        >
                                          <IoCodeSharp className="mr-2 h-5 w-5" />{" "}
                                          Get Code
                                        </button>

                                        <button
                                          className={`flex items-center w-full text-base px-4 py-2 ${
                                            model.status === "Deployment Ready"
                                              ? "text-black"
                                              : "text-gray-400 cursor-not-allowed"
                                          }`}
                                          disabled={model.status !== "Deployment Ready"}
                                          onClick={() => getUiModel(model, model.feature.service_ip)}
                                        >
                                          <IoCodeSharp className="mr-2 h-5 w-5" />{" "}
                                          Interactive UI
                                        </button>

                                        {/* Start/Stop Button */}
                                        {model.status === "Deployment Ready" ? (
                                          <button
                                            className={`flex items-center w-full text-base px-4 py-2 ${
                                              loading
                                                ? "text-gray-400 cursor-not-allowed"
                                                : "text-black"
                                            }`}
                                            onClick={() =>
                                              !loading &&
                                              handleStopDeploy(model)
                                            }
                                            disabled={loading}
                                          >
                                            {loading ? (
                                              <LoaderComponent />
                                            ) : (
                                              <FaRegStopCircle className="mr-2 h-5 w-5" /> // Use Stop icon for "Deployment Ready" status
                                            )}
                                            {loading ? "Stopping..." : "Stop"}
                                          </button>
                                        ) : model.status ===
                                          "Deployment Stopped" ? (
                                          <button
                                            className={`flex items-center w-full text-base px-4 py-2 ${
                                              loading
                                                ? "text-gray-400 cursor-not-allowed"
                                                : "text-black"
                                            }`}
                                            onClick={() =>
                                              !loading &&
                                              handleStartDeploy(model)
                                            }
                                            disabled={loading}
                                          >
                                            {loading ? (
                                              <LoaderComponent />
                                            ) : (
                                              <FaRegPlayCircle className="mr-2 h-5 w-5" /> // Use Play icon for "Deployment Stopped" status
                                            )}
                                            {loading ? "Starting..." : "Start"}
                                          </button>
                                        ) : (
                                          <button
                                            className="flex items-center w-full text-base px-4 py-2 text-gray-400 cursor-not-allowed"
                                            disabled
                                          >
                                            <FaRegPlayCircle className="mr-2 h-5 w-5" />
                                            Start / Stop
                                          </button>
                                        )}

                                        {/* Delete Button */}
                                        <button
                                          className={`flex items-center w-full text-base px-4 py-2 ${
                                            model.status === "Tuning Failed" ||
                                            model.status ===
                                              "Tuning Completed" ||
                                            model.status === "Deployment Ready"
                                              ? "text-black"
                                              : "text-gray-400 cursor-not-allowed"
                                          }`}
                                          disabled={
                                            model.status !== "Tuning Failed" &&
                                            model.status !==
                                              "Tuning Completed" &&
                                            model.status !== "Deployment Ready"
                                          }
                                          onClick={() =>
                                            handleDeleteModel(model._id, model)
                                          }
                                        >
                                          <FaRegTrashAlt className="mr-2 h-5 w-5" />{" "}
                                          Delete
                                        </button>
                                      </div>
                                    )}
                                    {/* Confirmation Modal for Model Deletion */}
                                    {showDeleteConfirmationModal &&
                                      deleteTunedModel._id === model._id && (
                                        <div className="fixed z-10 inset-0 overflow-y-auto">
                                          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                            <div
                                              className="fixed inset-0 transition-opacity"
                                              aria-hidden="true"
                                            >
                                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                            </div>
                                            <span
                                              className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                              aria-hidden="true"
                                            >
                                              &#8203;
                                            </span>
                                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                              <div>
                                                <div className="mt-3 text-center sm:mt-5">
                                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Are you sure you want to
                                                    delete this model?
                                                  </h3>
                                                </div>
                                                <div className="mt-5 sm:mt-6 flex justify-center">
                                                  <button
                                                    type="button"
                                                    className="cancel-button"
                                                    onClick={() =>
                                                      confirmDeleteModel()
                                                    } // Call confirmDeleteModel on click
                                                  >
                                                    Delete
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="general-button ml-5"
                                                    onClick={() =>
                                                      setShowDeleteConfirmationModal(
                                                        false
                                                      )
                                                    } // Close modal
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>

                      {/* Synthetic Data Tab */}
                      <div
                        className={`${openTab === 3 ? "block" : "hidden"}`}
                        id="link3"
                      >
                        <table className="w-full">
                          <thead className="bg-table-header-bg-color">
                            <tr>
                              <th className="py-4 px-10 text-sm font-semibold text-left">
                                Sample File
                              </th>
                              <th className="py-4 px-10 text-sm font-semibold text-left">
                                Generated Date
                              </th>
                              <th className="py-4 px-10 text-sm font-semibold text-left">
                                Generated File
                              </th>
                              <th className="py-4 px-10 text-sm font-semibold text-left">
                                Generated File Size
                              </th>
                              <th className="py-4 px-10 text-sm font-semibold text-left">
                                Report
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {synthHisRes
                              ?.sort(
                                (a, b) =>
                                  new Date(b.generate_date_time) -
                                  new Date(a.generate_date_time)
                              )
                              .map((ele) => (
                                <tr
                                  key={ele._id}
                                  className={`${
                                    ele._id % 2 === 0
                                      ? "bg-white"
                                      : "bg-light-gray-1"
                                  } w-full`}
                                >
                                  <td className="py-4 px-10 text-sm font-normal">
                                    {ele.filename}
                                  </td>
                                  <td className="py-4 px-10 text-sm font-normal">
                                    {convertToLocalTime(ele.generate_date_time)}
                                  </td>
                                  <td
                                    className="py-4 px-10 text-sm font-normal flex cursor-pointer"
                                    onClick={() =>
                                      handleDownload(
                                        ele.generated_output_file_link
                                      )
                                    }
                                  >
                                    Generated File
                                    <GrDocumentCsv className="mt-0.5 ml-0.5" />
                                  </td>
                                  <td className="py-4 px-10 text-sm font-normal">
                                    {ele.generated_csv_file_size}
                                  </td>
                                  <td
                                    className="py-4 px-10 text-sm font-normal flex cursor-pointer"
                                    onClick={() =>
                                      handleDownload(ele.report_file_link)
                                    }
                                  >
                                    Download Report{" "}
                                    <GrDocumentPdf className="mt-0.5 ml-0.5" />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {openAction && (
          <div
            ref={actionRef}
            className={`fixed z-10 mt-40 w-48 rounded-lg shadow-xl bg-white ring-1 ring-black ring-opacity-5 ${
              isBottomOverflowing ? "bottom-2" : "top-auto"
            } right-2`}
          >
            <div className="flex flex-col p-2">
              <button
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => handleOptionClick("viewDetail")}
              >
                <FiEye className="mr-2" /> View Detail
              </button>
              <button
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => handleOptionClick("deploy")}
                disabled
              >
                <TbExternalLinkOff className="mr-2 h-5 w-5 text-gray-400" />{" "}
                Deploy
              </button>
              <button
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                // onClick={() =>
                //   handleOptionClick("delete")
                // }
              >
                <FaRegTrashAlt className="mr-2 h-5 w-5" /> Delete
              </button>
            </div>
          </div>
        )}

        {/* Footer */}
        <div className={`mt-[9%] ${open ? "ml-[228px]" : "ml-[90px]"}`}>
          <Footer />
        </div>
      </div>
      {isDeployModalOpen && (
        <DeployModal
          isDeployModalOpen={isDeployModalOpen}
          setIsDeployModalOpen={setIsDeployModalOpen}
          // price={selectedPrice}
          modelName={selectedModel}
          modelId={selectedmodelId}
        />
      )}
    </div>
  );
}

export default MyInstance;

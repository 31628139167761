import React from "react";

const NVIDIAInferencingModels = () => {
  return (
    <div className="min-h-[600px] w-full bg-white px-8 pb-8 shadow-md text-center">
      <div className="pt-40">
        <p className="text-[20px] font-[600] pl-4 pt-4 pb-2">Coming Soon...</p>
        <p className="text-[20px] font-[600]">
          Build Generative AI With NVIDIA NIM
        </p>
        <div className="font-medium">
          <p className="text-[#7E7E7E]">
            Explore the latest optimized AI models , connect applications to
            data with NVIDIA NIM
          </p>
          <p className="text-[#7E7E7E]">
            Agent Blueprints, and deploy anywhere with NVIDIA NIM microservices.
          </p>

          <a
            href="https://www.nvidia.com/en-us/ai/"
            target="_blank"
            rel="noopener noreferrer"
            className="pr-2 pt-4 text-[#8F27C1] underline"
          >
            Learn more...
          </a>
        </div>
      </div>
    </div>
  );
};

export default NVIDIAInferencingModels;

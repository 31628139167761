import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import {
  Container,
  IconButton,
  LinearProgress,
  TextField,
} from "@mui/material";
import { Context } from "../../Context";
import {
  Add,
  DeleteOutlined,
  FileDownload,
  FolderOutlined,
  MoreVert,
} from "@mui/icons-material";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axios";
import Footer from "../Footer/Footer";
import { toast } from "react-toastify";
import ImportFileModal from "./ImportFileModal";

function DataStorage() {
  const { open } = useContext(Context);
  // const [recentFiles, setRecentFiles] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [importFile, setImportFile] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [storage, setStorage] = useState();
  const [folderRes, setFolderRes] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  // const handleFileUpload = (event) => {
  //   // const fileInput = event.target;
  //   // const file = fileInput.files[0];
  //   // if (uploadedFiles.length !== 0) {
  //   //     toast("Can Not Upload Multiple File");
  //   //     event.preventDefault();
  //   //     return;
  //   // }
  //   // if (file) {
  //   //     if (file.type !== 'text/csv') {
  //   //         toast('Please select a CSV file.');
  //   //         fileInput.value = '';
  //   //         event.preventDefault();
  //   //         return;
  //   //     }
  //   //     const fileSizeInGB = file.size / (1024 * 1024 * 1024);
  //   //     if (fileSizeInGB > 1) {
  //   //         toast("File size exceeds 1GB limit.");
  //   //         fileInput.value = '';
  //   //         event.preventDefault();
  //   //         return;
  //   //     }
  //   // }
  //   const files = event.target.files;
  //   handleFiles(files);
  //   setSelectedFile(event.target.files[0]);
  // };

  // const handleDragOver = (event) => {
  //   event.preventDefault();
  // };

  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const files = event.dataTransfer.files;
  //   handleFiles(files);
  //   setSelectedFile(files[0]);
  // };

  // const handleFiles = (files) => {
  //   if (uploadedFiles.length !== 0) {
  //     toast("Can Not Upload Multiple File");
  //     return;
  //   }
  //   const newFiles = Array.from(files);
  //   setUploadedFiles([...uploadedFiles, ...newFiles]);
  // };

  // const uploadBtn = async () => {
  //   const formData = new FormData();
  //   if (!selectedFile) {
  //     toast("Upload CSV File");
  //     return;
  //   }
  //   formData.append("df", selectedFile);
  //   try {
  //     const response = await axiosInstance.post(`/manage/upload`, formData);
  //     if (response?.data) {
  //       toast(response?.data?.message);
  //       return;
  //     }
  //   } catch (error) {
  //     toast(error.response.data.error);
  //   }
  // };

  const navigate = useNavigate();
  useEffect(() => {
    const storageUses = () => {
      const config = {
        method: "get",
        url: "/manage/storage-usage",
      };
      axiosInstance(config)
        .then((response) => {
          setStorage(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    // const allFolder = () => {
    //     const config = {
    //         method: 'get',
    //         url: '/manage/folders',
    //     };
    //     axiosInstance(config)
    //         .then((response) => {
    //             setFolderRes(response.data)
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }
    const recentFile = () => {
      const config = {
        method: "get",
        url: "/manage/folder-content",
      };
      axiosInstance(config)
        .then((response) => {
          // setRecentFiles(response.data.files)
          // setFolderRes(response.data.folders)
          setFolderRes(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    recentFile();
    // allFolder();
    storageUses();
  }, []);
  // State to track whether "View All" has been clicked
  const [viewAll, setViewAll] = useState(false);

  // Determine the folders to display
  const displayedFolders = viewAll
    ? folderRes?.folders
    : folderRes?.folders.slice(0, 3);

  const handleDownload = (url) => {
    window.open(url, "_blank");
  };

  const handleDelete = (ele) => {
    axiosInstance
      .delete("/manage/delete", {
        data: {
          target: ele,
        },
      })
      .then((response) => {
        console.log(response.data.message);
        toast(response.data.message);
      })
      .catch((error) => {
        toast(error.response.data.message);
      });
  };

  return (
    <>
      <div className="flex w-full min-h-screen">
        <Sidebar />
        <div className="flex flex-col w-full">
          <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
            <Navbar />
          </div>
          {/* Page Content */}
          <div
            className={`${open ? "ml-[228px]" : "ml-[90px]"} px-8 pt-24 mb-24`}
          >
            {/* Your main content goes here */}
            <Container>
              <div className="pb-8">
                <p className="font-semibold text-base text-secondry-clr">
                  Storage
                </p>
                <p className="text-base font-normal text-gray-70">
                  All of project file in one place
                </p>
              </div>

              <div className="flex justify-between gap-3">
                <div>
                  <div className="flex gap-3">
                    {/*<div className='bg-white px-3 py-3 flex items-center justify-between min-w-[280px] h-[60px] shadow'>
                                            <div className='flex items-center gap-3'>
                                                <div className='w-10 h-10 rounded-full p-1 bg-[#F5F7FF] flex items-center justify-center'>
                                                    <FolderOutlined />
                                                </div>
                                                <span className='text-sm font-semibold text-secondry-clr'>Create Folder</span>
                                            </div>
                                            <Add onClick={() => setShowConfirmationModal(true)} />
                                        </div>*/}

                    <div
                      className="bg-white px-3 py-3 flex items-center justify-between min-w-[280px] h-[60px] shadow cursor-pointer"
                      onClick={() => setImportFile(true)}
                    >
                      <div className="flex items-center gap-3">
                        <div className="w-10 h-10 rounded-full p-1 bg-[#F5F7FF] flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M12 12L9 9L6 12"
                              stroke="#282828"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9 9V15.75"
                              stroke="#282828"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M15.2926 13.7926C16.0241 13.3938 16.602 12.7628 16.935 11.9991C17.268 11.2354 17.3373 10.3825 17.1317 9.57513C16.9262 8.76772 16.4577 8.05174 15.8001 7.5402C15.1425 7.02865 14.3332 6.75066 13.5001 6.75011H12.5551C12.3281 5.87204 11.905 5.05686 11.3176 4.36585C10.7301 3.67485 9.99373 3.126 9.16367 2.76057C8.33361 2.39513 7.43151 2.22263 6.52518 2.25602C5.61886 2.28941 4.73189 2.52784 3.93098 2.95336C3.13006 3.37888 2.43603 3.98044 1.90106 4.7128C1.36609 5.44516 1.00411 6.28926 0.842327 7.18166C0.680545 8.07405 0.723171 8.99151 0.967002 9.86506C1.21083 10.7386 1.64952 11.5455 2.25009 12.2251"
                              stroke="#282828"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 12L9 9L6 12"
                              stroke="#282828"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <span className="text-sm font-semibold text-secondry-clr">
                          Import File
                        </span>
                      </div>
                      <Add />
                    </div>
                  </div>

                  <div className="flex justify-between py-6">
                    <span className="font-semibold text-base">Folder</span>
                    <span
                      className="font-semibold text-base cursor-pointer text-gen-color underline"
                      onClick={() => setViewAll(!viewAll)}
                    >
                      {viewAll ? "Show Less" : "View All"}
                    </span>
                  </div>
                  <div className="grid grid-cols-3 gap-3">
                    {displayedFolders?.map((ele, i) => (
                      <div
                        key={i}
                        className="bg-white px-3 py-3 shadow flex items-center justify-between h-[60px] cursor-pointer"
                        onClick={() =>
                          navigate(`/userFolder?folderName=${ele.name}`)
                        }
                      >
                        <div className="flex items-center gap-3">
                          <div className="w-10 h-10 rounded-full p-1 bg-[#F5F7FF] flex items-center justify-center">
                            <FolderOutlined />
                          </div>
                          <span className="text-sm font-semibold text-secondry-clr">
                            {ele?.name}
                          </span>
                        </div>
                        <MoreVert />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bg-white shadow py-6">
                  <div className="px-6 pb-3 w-80">
                    <div className="flex justify-between">
                      <p className="font-semibold text-base text-secondry-clr">
                        Storage
                      </p>
                      <p>
                        <span className="font-semibold text-sm">5</span>{" "}
                        <span className="font-semibold text-xs">GB</span>{" "}
                        <span className="font-medium text-xs">Free</span>
                      </p>
                    </div>
                    <div className="py-3">
                      <LinearProgress
                        variant="determinate"
                        value={parseFloat(storage?.usage?.split(" ")[0])}
                        className="p-1.5 rounded"
                      />
                    </div>
                    <p>
                      <span className="font-semibold text-sm">
                        {storage?.usage}
                      </span>{" "}
                      <span className="font-medium text-xs">Used</span>
                    </p>
                  </div>
                  {/* <hr />
                                    <div className='px-6 text-center pt-6'>
                                        <span className="font-semibold text-base underline text-gen-color">Add More Storage</span>
                                    </div> */}
                </div>
              </div>
              <div className="flex justify-between py-4">
                <span className="font-semibold text-base">Recent Files</span>
                {/* <div>Filter By:</div> */}
              </div>
              <table className="w-full">
                <thead className="bg-table-header-bg-color">
                  <tr>
                    <th className="py-4 px-10 text-sm font-semibold text-left">
                      File Name
                    </th>
                    <th className="py-4 px-10 text-sm font-semibold text-left">
                      Size
                    </th>
                    <th className="py-4 px-10 text-sm font-semibold text-left">
                      Last Modification
                    </th>
                    <th className="py-4 px-10 text-sm font-semibold text-left">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {folderRes?.files?.map((ele, i) => (
                    <tr
                      key={i}
                      className={`${
                        i % 2 === 0 ? "bg-white" : "bg-light-gray-1"
                      } w-full`}
                    >
                      <td className="py-2 px-10 text-sm font-normal">
                        {ele?.name}
                      </td>
                      <td className="py-2 px-10 text-sm font-normal">
                        {ele?.size}
                      </td>
                      <td className="py-2 px-10 text-sm font-normal">
                        {ele?.last_modified}
                      </td>
                      <td className="py-2 px-10 text-sm font-normal">
                        <IconButton
                          onClick={() => handleDownload(ele?.download_link)}
                        >
                          <FileDownload />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(ele?.name)}>
                          <DeleteOutlined />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Container>
          </div>
          <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
            <Footer />
          </div>
        </div>
      </div>
      {showConfirmationModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto">
              {/*content*/}
              <div className="border-0 w-[500px] rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <div>
                    <h5 className="text-xl font-semibold py-1">
                      Create Folder
                    </h5>
                  </div>

                  <button
                    className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowConfirmationModal(false)}
                  >
                    <MdClose className="w-5 h-5" />
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <TextField
                    id="outlined-basic"
                    label="Folder Name"
                    variant="outlined"
                    fullWidth
                    value={templateName}
                    onChange={(event) => setTemplateName(event.target.value)}
                  />

                  <div className="mt-5 flex justify-end gap-5">
                    <button
                      type="button"
                      className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-10 py-3"
                      onClick={() => setShowConfirmationModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-10 py-3"
                      // onClick={saveToTemplate}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* ImportFileModal */}

      {
        importFile && (
          <ImportFileModal isOpen={importFile} setIsOpen={setImportFile} />
        )
        // importFile ? (
        //   <>
        //     <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        //       {/* Dark Overlay */}
        //       <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
        //       {/* Main Component */}
        //       <div className="relative mx-auto my-auto max-w-3xl w-full z-50">
        //         {/*content*/}
        //         <div className="border-0 w-[700px] rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
        //           {/*header*/}
        //           <div className="flex justify-between items-center p-5 border-b border-solid border-slate-200 rounded-t">
        //             <span className="font-semibold text-[18px]">Import File</span>

        //             <button
        //               className="px-4"
        //               type="button"
        //               onClick={() => setImportFile(false)}
        //             >
        //               <MdClose className="w-5 h-5 text-2xl" />
        //             </button>
        //           </div>
        //           {/*body*/}
        //           <div className="relative p-6 flex-auto">
        //             <div
        //               className="border-2 border-dashed rounded py-10 my-2 text-center"
        //               onDragOver={handleDragOver}
        //               onDrop={handleDrop}
        //             >
        //               <div className="text-secondry-clr font-medium text-base">
        //                 <label
        //                   htmlFor="file-upload"
        //                   className="cursor-pointer rounded-md py-2 px-4 inline-block underline"
        //                 >
        //                   Click to upload
        //                 </label>
        //                 <input
        //                   id="file-upload"
        //                   type="file"
        //                   onChange={handleFileUpload}
        //                   style={{ display: "none" }}
        //                 />
        //                 <span className="-ml-3">or drag & drop</span>
        //               </div>
        //             </div>

        //             {/* Modal Buttons */}
        //             <div className="mt-5 flex justify-end gap-5">
        //               <button
        //                 type="button"
        //                 className="hover:bg-gray-200 hover:text-gray-600 hover:border-gray-300 hover:shadow-md transition duration-200 ease-in-out focus:outline-none rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-8 py-3"
        //                 onClick={() => setImportFile(false)}
        //               >
        //                 Cancel
        //               </button>
        //               <button
        //                 type="button"
        //                 className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-10 py-3"
        //                 onClick={uploadBtn}
        //               >
        //                 Save
        //               </button>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //     {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
        //   </>
        // ) : null
      }
    </>
  );
}

export default DataStorage;

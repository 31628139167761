import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import { Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./Model.css";
import AIModels from "./Model Tabs/AIModels";
import HuggingFaceModels from "./Model Tabs/HuggingFaceModels";
import NVIDIAInferencingModels from "./Model Tabs/NVIDIAInferencingModels";

const ModelHome = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    switch (tab) {
      case "alModels":
        setSelectedTab(0);
        break;
      case "huggingFaceModels":
        setSelectedTab(1);
        break;
      case "NVIDIAInferencingModels":
        setSelectedTab(2);
        break;
      default:
        setSelectedTab(0);
        break;
    }
  }, [location.search]);

  const handleTabChange = (tabIndex) => {
    setSelectedTab(tabIndex);
    const tabQuery = [
      "alModels",
      "huggingFaceModels",
      "NVIDIAInferencingModels",
    ];
    navigate(`?tab=${tabQuery[tabIndex]}`);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <AIModels />;
      case 1:
        return <HuggingFaceModels />;
      case 2:
        return <NVIDIAInferencingModels />;
      default:
        return <AIModels />;
    }
  };

  return (
    <div className="flex w-full min-h-screen">
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full ml-[90px]">
        <Navbar className="flex-shrink-0 fixed w-full" />
        <div className="px-2 pt-[84px] mb-24">
          <Container sx={{ maxWidth: { xs: "100%", sm: "1252px" } }}>
            <div className="flex justify-between py-4">
              <div>
                <h2 className="text-2xl font-bold text-heading-black">
                  Model Studio
                </h2>
                <p className="text-base font-normal text-gray-70 pt-1">
                  Train, Tune, Deploy AI models.
                </p>
              </div>
            </div>
            <div className="min-h-[58px] px-8 w-full bg-white shadow-md flex gap-x-6 items-end justify-start overflow-x-auto">
              {[
                "Open Source AI Models",
                "Hugging Face Models",
                "NVIDIA Inferencing Models",
              ].map((tab, index) => (
                <span
                  key={index}
                  onClick={() => handleTabChange(index)}
                  className={`min-w-fit text-[18px] font-[500] cursor-pointer pb-3 px-1 border-b-[3px]  ${
                    selectedTab === index
                      ? "text-gen-color font-[700] border-b-gen-color"
                      : "border-white"
                  }`}
                >
                  {tab}
                </span>
              ))}
            </div>
            <hr />
            {renderTabContent()}
          </Container>
        </div>
        <Footer className="ml-[90px]" />
      </div>
    </div>
  );
};

export default ModelHome;

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import axiosInstance from "../../api/axios";

const DeployModal = ({
  isDeployModalOpen,
  setIsDeployModalOpen,
  modelName,
}) => {
  const [gpuPlans, setGpuPlans] = useState({});
  const [selectedGpu, setSelectedGpu] = useState("");
  const [selectedGpuPrice, setSelectedGpuPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [isDeploying, setIsDeploying] = useState(false); // New state to track deploying status

  useEffect(() => {
    // Fetch the GPU plans from the API when the modal is opened
    const fetchGpuPlans = async () => {
      try {
        const response = await axiosInstance.get("/nocode/gpu_plans");
        setGpuPlans(response.data.data); // Store the response data (the object with GPU names)
      } catch (error) {
        console.error("Error fetching GPU plans:", error);
      }
    };

    if (isDeployModalOpen) {
      fetchGpuPlans();
    }
  }, [isDeployModalOpen]);

  const handleClose = () => {
    setIsDeployModalOpen(false);
  };

  const handleGpuChange = (event) => {
    const gpuName = event.target.value;
    setSelectedGpu(gpuName);

    // Fetch the price for the selected GPU
    const gpuDetails = gpuPlans[gpuName]?.[0]; // Assuming each GPU has an array with one object
    setSelectedGpuPrice(gpuDetails?.price || 0);
  };

  const handleDeployModel = async () => {
    setIsDeploying(true); // Set deploying state to true
    // setIsLoading(true); // Show loader
    try {
      // Make the API call to deploy the model
      const payload = {
        model_name: modelName, // Pass the modelName from props
        price: selectedGpuPrice,
        gpu_name: selectedGpu,
        number_of_gpu: 1, // Always set this to 1 as per your requirement
      };

      const response = await axiosInstance.post("/tune/deploy_model", payload);
      console.log("Deployment successful", response.data);

      // Handle post-deployment steps
      setTimeout(() => {
        setIsDeployModalOpen(false); // Close the modal after 5 seconds
        setIsLoading(false); // Hide loader
        setIsDeploying(false); // Reset deploying state
        window.location.reload(); // Reload the page after deployment
      }, 5000); // Delay for 5 seconds
    } catch (error) {
      console.error("Error deploying model:", error);
      setIsLoading(false); // Hide loader
      setIsDeploying(false); // Reset deploying state to show "Deploy"
    } finally {
      setIsLoading(false); // Hide loader after API call completes
      setIsDeployModalOpen(false);
    }
  };

  return (
    <div>
      {isDeployModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Dark Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
          {/* Modal Content */}
          <div className="relative z-50 w-full max-w-xl mx-auto bg-white rounded-lg shadow-lg">
            {/* Modal Header */}
            <div className="flex items-center justify-between p-5 border-b border-gray-200">
              <h4 className="text-lg font-semibold">Deploy</h4>
              <button
                className="p-1 transition-colors duration-150 ease-in-out rounded-full hover:bg-gray-200"
                onClick={handleClose}
                // disabled={isDeploying} // Disable close button while deploying
              >
                <MdClose className="w-6 h-6" />
              </button>
            </div>

            {/* Modal Body */}
            <Box className="p-6">
              {isLoading ? (
                <div className="flex justify-center">
                  {/* Show loader when isLoading is true */}
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="gpu-select-label">Select GPU</InputLabel>
                    <Select
                      labelId="gpu-select-label"
                      value={selectedGpu}
                      onChange={handleGpuChange}
                      label="Select GPU"
                      // disabled={isDeploying} // Disable select during deployment
                    >
                      {Object.keys(gpuPlans).length > 0 ? (
                        Object.keys(gpuPlans).map((gpuName) => (
                          <MenuItem key={gpuName} value={gpuName}>
                            {gpuName}
                            <span className="text-green-600 font-bold">
                              {" "}
                              ($ {gpuPlans[gpuName][0].price}/hr)
                            </span>
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No GPUs Available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>

            {/* Modal Footer */}
            {!isLoading && (
              <div className="flex justify-end p-4 border-t border-gray-200">
                <button
                  className="h-[42px] w-[120px] px-4 py-2 mr-2 text-sm font-bold text-black bg-white border border-black rounded-lg hover:bg-gray-200 hover:border-none"
                  onClick={handleClose}
                  // disabled={isDeploying} // Disable cancel button during deployment
                >
                  Cancel
                </button>
                <button
                  className={`px-4 py-2 mr-2 text-sm font-bold rounded-lg h-[42px] w-[120px] transition-colors duration-150 ease-in-out ${
                    isDeploying
                      ? "text-gray-400 bg-gray-200 cursor-not-allowed"
                      : "text-[#8F27C1] bg-[#8F27C126] border border-[#8F27C133] hover:bg-[#8F27C140] hover:text-[#7A1EA8]"
                  }`}
                  onClick={handleDeployModel}
                  // disabled={isDeploying} // Disable deploy button while deploying
                >
                  {isDeploying ? "Deploying..." : "Deploy"}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DeployModal;
